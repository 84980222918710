<template>
  <v-container class="profile-container">
    <v-card class="profile-card">
      <v-card-title class="profile-header">
        <v-avatar size="64" class="mr-4">
          <v-icon large>mdi-account</v-icon>
        </v-avatar>
        <div>
          <h2>
            {{ user.firstName || "Not submitted by user" }}
            {{ user.lastName || "" }}
          </h2>
          <h4>{{ user.email || "Not submitted by user" }}</h4>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="mt-5 d-flex align-center justify-space-between mb-5">
          <div>
            <v-chip :color="statusColorMapper(user.status)" variant="flat">
              {{ statusMapper(user.status) || "No status details." }}
            </v-chip>
          </div>
          <div>
            <v-btn dark color="red" @click="blockUser">Block User</v-btn>
          </div>
        </div>

        <div>
            <v-btn dark color="primary" @click="updateCode">Update Project Code</v-btn>
          </div>

        <v-row>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Name</v-list-item-title>
                <v-list-item-subtitle>{{
                  `${user.firstName} ${user.lastName}` ||
                  "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Phone Number</v-list-item-title>
                <v-list-item-subtitle>{{
                  `${user.phoneNumber}` || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>{{
                  `${user.email}`
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Role</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.role || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Points</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.points !== undefined
                    ? user.points
                    : "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Referral Code</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.referralCode || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Project Code</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.projectCode || "No Project Code linked"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Country</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.country || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Gender</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.gender || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Family Status</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.maritalStatus || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Occupation</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.occupation || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Child Age Group</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.childAgeGroup || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Race</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.race || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>State</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.state || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title>Bank Details</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Account Name</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.bankDetails?.accountName || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Account Number</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.bankDetails?.accountNumber || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>IC</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.bankDetails?.ic || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Bank Name</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.bankDetails?.bankName || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title>Paypal</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Paypal Info</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.paypalEmail || "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title>Subscription</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Newsletter Subscribed</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.newsletterSubscribed ? "Yes" : "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-title>Other Information</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Joined</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.createdAt
                    ? $moment(user.createdAt).format("DD-MMM-YYYY")
                    : "Not submitted by user"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Updated Info At</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.updatedAt
                    ? $moment(user.updatedAt).format("DD-MMM-YYYY")
                    : "No Info"
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-title>People Referred</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>People Referred</v-list-item-title>
                <v-list-item-subtitle>{{
                  user.peopleReferred
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-btn dark color="red" @click="getPeopleReferred"
                  >Get People Referred</v-btn
                >
              </v-list-item-content>
            </v-list-item>
            <v-list v-if="peopleReferred.length > 0">
              <v-list-item
                v-for="person in peopleReferred"
                :key="person._id"
                class="clickable-list-item"
              >
                <v-list-item-content>
                  <!-- Opening the profile in a new tab -->
                  <a
                    :href="`/mobile/v2/userProfile/${person._id}`"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <v-list-item-title>{{ person.email }}</v-list-item-title>
                  </a>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-expansion-panels class="mt-10">
      <v-expansion-panel @click.native="openCashoutExpansionPanel()">
        <v-expansion-panel-header> Cashouts </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="cashoutHeader"
                :items="cashoutData"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Cashout</v-toolbar-title>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel @click.native="openPointHistoryExpansionPanel()">
        <v-expansion-panel-header> Cashout History </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card>
            <v-card-text>
              <v-data-table
                :headers="historyHeader"
                :items="pointHistoryData"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>History</v-toolbar-title>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-dialog width="600" v-model="dialog">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title
          >Add Project Code</v-card-title
        >
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm8 md8>
              <v-select
                v-model="selectedProjectCode"
                :items="formattedProjectCodes"
                item-text="label"
                item-value="code"
                label="Select Project Code"
                outlined
                dense
                class="mb-4"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updateProjectCode">Update Project Code</v-btn>
          <v-btn color="primary" @click="closeDialog">Close Dialog</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import RestResource from "../../..//services/dataServiceMobileV2.js";
const service = new RestResource();

export default {
  data() {
    return {
      user: [],
      peopleReferred: [],
      cashoutData: [],
      pointHistoryData: [],
      isExpanded: false,
      dialog: false,
      projectCode: [],
      selectedProjectCode: null,
      cashoutHeader: [
        { text: "Points Redeemed", value: "pointsRedeemed" },
        { text: "CashoutType", value: "cashoutType" },
        { text: "Status", value: "status" },
        { text: "Date", value: "createdAt" },
      ],
      historyHeader: [
        { text: "Points", value: "points" },
        { text: "Source", value: "sourceType" },
        { text: "Type", value: "type" },
        { text: "Date", value: "createdAt" },
      ],
    };
  },

  computed: {
    formattedProjectCodes() {
      return this.projectCode.map((project) => ({
        label: `${project.code} - ${project.projectName}`,
        code: project.code,
      }));
    },
  },

  mounted() {
    this.loadUserProfile();
    this.getProjectCodes();
  },

  watch: {
    isExpanded(newVal) {
      if (newVal) {
        this.openCashoutExpansionPanel();
      }
    },
  },

  methods: {
    async loadUserProfile() {
      this.$setLoader();
      let res = await service.getUserById({
        userId: this.$route.params.userId,
      });
      this.user = res.data.data;
      this.$disableLoader();
    },
    goToUserProfile(userId) {
      console.log("user", userId);
      this.$router.push({ name: "MobileV2UserProfile", params: { userId } });
    },
    statusMapper(text) {
      switch (text) {
        case "P":
          return "Pending OTP Verification";
        case "A":
          return "Active";
        case "D":
          return "Deactivated";
        case "B":
          return "Blocked";
        default:
          return "Possible Old User";
      }
    },
    statusColorMapper(text) {
      switch (text) {
        case "P":
          return "yellow";
        case "A":
          return "green";
        case "D":
          return "gray";
        case "B":
          return "red";
        default:
          return "primary";
      }
    },

    async blockUser() {
      this.$setLoader();
      try {
        await service.updateUserStatus({
          userId: this.$route.params.userId,
          status: "B",
        });
      } catch (error) {
        console.error("Error blocking user:", error);
        alert(error.message || "An unexpected error occurred.");
      } finally {
        this.$disableLoader();
      }
    },

    async getPeopleReferred() {
      this.$setLoader();
      try {
        const data = await service.getPeopleReferred({
          userId: this.$route.params.userId,
        });
        this.peopleReferred = data.data.data;
      } catch (error) {
        console.error("Error blocking user:", error);
        alert(error.message || "An unexpected error occurred.");
      } finally {
        this.$disableLoader();
      }
    },

    async getCashoutData() {
      this.$setLoader();
      try {
        const data = await service.getCashoutDataByUser({
          userId: this.$route.params.userId,
        });
        this.cashoutData = data.data.data;
      } catch (error) {
        console.error("Error error");
        alert(error.message || "An unexpected error occurred.");
      } finally {
        this.$disableLoader();
      }
    },

    async getProjectCodes() {
      this.$setLoader();
      let res = await service.getProjectCodes();
      this.projectCode = res.data.data;
      this.$disableLoader();
    },

    async getPointHistoryData() {
      this.$setLoader();
      try {
        const data = await service.getPointHistoryData({
          userId: this.$route.params.userId,
        });
        console.log("data =>>", data);
        this.pointHistoryData = data.data.data;
      } catch (error) {
        console.error("Error error");
        alert(error.message || "An unexpected error occurred.");
      } finally {
        this.$disableLoader();
      }
    },

    async updateProjectCode() {
      this.$setLoader();
      try {
        await service.updateProjectCode({
          userId: this.$route.params.userId,
          projectCode: this.selectedProjectCode
        })
        this.loadUserProfile();
      } catch (error) {
        alert(error)
      } finally {
        this.$disableLoader();
        this.dialog = false
      }
    },

    openCashoutExpansionPanel() {
      this.getCashoutData();
    },

    openPointHistoryExpansionPanel() {
      this.getPointHistoryData();
    },

    updateCode() {
      this.dialog = true;
    },

    closeDialog() {
      this.dialog = false;
    }
  },
};
</script>

<style scoped>
h2 {
  margin-bottom: 0;
}

h4 {
  margin-top: 0;
  color: gray;
}

.profile-container {
  background-color: #f5f5f5;
  /* Light grey background */
  padding: 20px;
  border-radius: 8px;
}

.profile-card {
  background-color: #ffffff;
  /* White background for the card */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-header {
  background-color: #3f51b5;
  /* Primary color background */
  color: white;
  padding: 20px;
  border-radius: 8px 8px 0 0;
}

.profile-header h2,
.profile-header h4 {
  margin: 0;
}

.profile-header h4 {
  color: #e0e0e0;
}

.mt-5 {
  margin-top: 1.25rem;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-space-between {
  justify-content: space-between;
}

.clickable-list-item {
  cursor: pointer;
}
</style>
